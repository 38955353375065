<template>
  <b-notification
    v-if="$task.hasResellerOrigins"
    class="has-padding-75 is-size-7"
    :closable="false"
    type="is-warning"
  >
    <p>
      <b-icon icon="exclamation-triangle" size="is-small" />
      This task is linked to a reseller's account – avoid any references to
      'Fixed' in this thread.
    </p>
  </b-notification>
</template>

<script>
export default {
  name: "TaskResellerClientWarning",
  inject: ["$taskProvider"],
  computed: {
    $task() {
      return this.$taskProvider();
    }
  }
};
</script>
